import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import TechTeamBg from "../../images/unrealEngineers/techteambg.png"

const TechTeam = ({ strapiData }) => {
  return (
    <div
      className="p-85"
      style={{
        backgroundImage: `url(${TechTeamBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <Container>
        <h2
          className="text-center mb-4 mt-4"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />

        <p
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row className="mt-5 pt-2">
          <Col xs={12}>
            <img
              decoding="async"
              loading="lazy"
              src={strapiData?.secImages[0].localFile.publicURL}
              alt={"A Promising Unreal Tech Engineering Team"}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TechTeam
