import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import HireBg from "../../images/unrealEngineers/hireteam.png"
import * as styles from "./HireTeam.module.scss"

const HireTeam = ({ strapiData }) => {
  return (
    <div
      style={{
        backgroundImage: "linear-gradient(90deg, #2176ff 0.7%, #1ca3e9 98.1%)",
      }}
    >
      <div
        className={`p-5 ${styles.hireTeanSection}`}
        style={{
          backgroundImage: `url(${HireBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Row className="gap-30 align-items-center">
            <Col xs={12} md={12} lg={5}>
              <img
                decoding="async"
                loading="lazy"
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={"Grab Yourself"}
              />
            </Col>
            <Col xs={12} md={12} lg={7}>
              <h2
                className="text-light mb-3"
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div
                className="text-light mb-5"
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_white_border"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default HireTeam
