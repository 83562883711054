import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./WhyHire.module.scss"

const WhyHire = ({ strapiData }) => {
  return (
    <div className="p-85">
      <Container>
        <h2
          className="text-center"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="gap-30 mt-5">
          {strapiData?.cards?.map((e, i) => (
            <Col xs={12} md={6} lg={4} key={i}>
              <div className={`p-4 ${styles.subCard}`}>
                <div className={`d-flex align-items-center ${styles.icon}`}>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={e?.image1[0].localFile.publicURL}
                    alt={e?.title}
                  />
                  <h3 className="m-0">{e?.title}</h3>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default WhyHire
