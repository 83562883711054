// extracted by mini-css-extract-plugin
export var bgBlue = "SkillSet-module--bgBlue--35b91";
export var bgGreen = "SkillSet-module--bgGreen--8c2cb";
export var bgPurple = "SkillSet-module--bgPurple--b699c";
export var bgRed = "SkillSet-module--bgRed--afddc";
export var bgWhite = "SkillSet-module--bgWhite--e460d";
export var bgYellow = "SkillSet-module--bgYellow--140e5";
export var cardHead = "SkillSet-module--cardHead--12667";
export var icon = "SkillSet-module--icon--a7bfb";
export var skillSection = "SkillSet-module--skillSection--dabec";
export var subCard = "SkillSet-module--subCard--75e52";