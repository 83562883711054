import React, { useEffect, useRef, useState } from "react"
import Container from "react-bootstrap/Container"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./SkillSet.module.scss"
import "./SkillSet.scss"

const SkillSet = ({ strapiData }) => {
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)
  const slide1 = useRef(null)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
  }, [current, current_1, current_2])

  const settings = {
    dots: true,
    autoplay: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    lazyLoad: "ondemand",
    beforeChange: (current, next) => setCurrent(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 768,
        arrows: false,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <div className={`p-85 skilSetSection ${styles.skillSection}`}>
      <Container>
        <h2
          className="text-center mb-4"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className="mt-5">
          <Slider {...settings} ref={slide1}>
            {strapiData?.cards?.map((e, i) => (
              <div className={`p-3 ${styles.subCard}`} key={i}>
                <div
                  className={`d-flex align-items-center justify-content-center flex-column p-4 ${
                    styles.icon
                  }  ${
                    i === 0
                      ? styles.bgWhite
                      : i === 1
                      ? styles.bgBlue
                      : i === 2
                      ? styles.bgYellow
                      : i === 3
                      ? styles.bgPurple
                      : i === 4
                      ? styles.bgGreen
                      : ""
                  }  `}
                >
                  <img
                    decoding="async"
                    loading="lazy"
                    src={e?.image1[0]?.localFile?.publicURL}
                    alt="icon"
                  />
                  <h3 className={styles.cardHead}>{e?.title}</h3>
                  <div
                    className="text-center"
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default SkillSet
