import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import One from "../../images/unrealEngineers/01.png"
import Two from "../../images/unrealEngineers/02.png"
import Three from "../../images/unrealEngineers/03.png"
import Four from "../../images/unrealEngineers/04.png"
import Five from "../../images/unrealEngineers/05.png"
import Lines from "../../images/unrealEngineers/lines.png"
import * as styles from "./DevelopersTeam.module.scss"
import "../home-sections/DownloadGuides.scss"
const bg = [One, Two, Three, Four, Five]

const DevelopersTeam = ({ strapiData }) => {
  return (
    <div
      className="p-85"
      style={{
        backgroundImage: `url(${Lines})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom 80px",
      }}
    >
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />

        <Row className="gap-50 mt-5">
          {strapiData?.cards.map((e, i) => (
            <Col xs={12} md={6} lg={4} key={i}>
              <div
                className={`px-4 ${styles.subCard}`}
                style={{
                  backgroundImage: `url(${bg[i]})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right top",
                }}
              >
                <div className={`d-flex align-items-center ${styles.icon}`}>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={e.image1[0].localFile.publicURL}
                    alt={e?.title}
                  />
                  <h3 className="text-primary m-0">{e?.title}</h3>
                </div>
                <p
                  className="py-4"
                  dangerouslySetInnerHTML={{
                    __html: e?.description?.description,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default DevelopersTeam
