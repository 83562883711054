import { graphql } from "gatsby"
import React from "react"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import DevelopersTeam from "../components/hire-unreal-engineers/DevelopersTeam"
import HireTeam from "../components/hire-unreal-engineers/HireTeam"
import Skillset from "../components/hire-unreal-engineers/SkillSet"
import TechTeam from "../components/hire-unreal-engineers/TechTeam"
import WhyHire from "../components/hire-unreal-engineers/WhyHire"
import Banner2 from "../components/services2/Banner2"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/hire_unreal_engine_developers_5134626ee7.webp"
    />
  )
}
const HireUnrealEngineers = ({ data }) => {
  const banner = data?.strapiPage?.sections[0]
  const tech = data?.strapiPage?.sections[1]
  const dev = data?.strapiPage?.sections[2]
  const skil = data?.strapiPage?.sections[3]
  const why = data?.strapiPage?.sections[4]
  const hire = data?.strapiPage?.sections[5]
  const faqs = data?.strapiPage?.sections[6]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner2 strapiData={banner} />
      <TechTeam strapiData={tech} />
      <DevelopersTeam strapiData={dev} />
      <Skillset strapiData={skil} />
      <WhyHire strapiData={why} />
      <HireTeam strapiData={hire} />
      <Faqs strapiData={faqs} />
    </MainLayout>
  )
}

export const query = graphql`
  query HireUnrealEngineers {
    strapiPage(slug: { eq: "hire-unreal-engine-developers" }) {
      sections {
        title
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            localFile {
              publicURL
            }
          }
          image2 {
            localFile {
              publicURL
            }
          }
        }
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default HireUnrealEngineers
